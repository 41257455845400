import React, { FC, HTMLAttributes, ReactElement } from "react";
import { Button, Text, Tooltip } from "@epignosis_llc/gnosis";
import { selectCardStyles } from "./styles";

type SelectCardProps = {
  id: string;
  icon: ReactElement;
  label: string | ReactElement;
  compact?: boolean;
  iconTooltipContent?: string | JSX.Element;
  onClick?: () => void;
};

const SelectCard: FC<HTMLAttributes<HTMLDivElement> & SelectCardProps> = ({
  id,
  icon,
  label,
  compact,
  iconTooltipContent,
  onClick,
  ...rest
}) => {
  return (
    <div data-testid={id} onClick={onClick} {...rest} css={selectCardStyles}>
      {compact ? (
        <Tooltip content={label}>
          <Button key={id} variant="ghost" aria-label={label.toString()}>
            <span className="select-card-icon">{icon}</span>
          </Button>
        </Tooltip>
      ) : (
        <>
          <Tooltip content={iconTooltipContent} disabled={!iconTooltipContent}>
            <span className="select-card-icon">{icon}</span>
          </Tooltip>
          <Text className="select-card-label" fontSize="md" weight="700">
            {label}
          </Text>
        </>
      )}
    </div>
  );
};

export default SelectCard;
