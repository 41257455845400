/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-console */
import { useEffect } from "react";
import { useConfigurationStore } from "@stores";
import { useAuth, useZendeskConfigData } from "@hooks";
import { ZendeskTokenRes } from "types/responses";
import authService from "@utils/services/AuthService";
import { userRoles } from "@constants/index";

// extend window interface
declare const window: {
  zE: any;
  zESettings: any;
} & Window;

const ZENDESK_SCRIPT_ID = "ze-snippet";
const ZENDESK_SCRIPT_URL = "https://static.zdassets.com/ekr/snippet.js?key=";

const canUseDOM = (): boolean => {
  return (
    typeof window !== "undefined" &&
    typeof window.document !== "undefined" &&
    typeof window.document.createElement !== "undefined"
  );
};

export const ZendeskAPI = (...args: any[]): void => {
  if (canUseDOM() && window.zE) {
    window.zE.apply(null, args);
  } else {
    // console.warn("Zendesk is not initialized yet");
  }
};

export const logoutZendesk = (): void => {
  ZendeskAPI("webWidget", "logout");
  closeAndHideZendeskWidget();

  const scriptElement = document.getElementById(ZENDESK_SCRIPT_ID);
  const uniqueId = scriptElement?.dataset.uniqueId;

  if (scriptElement && uniqueId === "help-script") {
    scriptElement.remove();
  }
};

export const removeZendeskScripts = (): void => {
  // Remove the Zendesk script element from the DOM
  const zendeskScript = document.getElementById("ze-snippet");
  zendeskScript?.remove();

  // Select the launcher iframe
  const launcherIframe = document.querySelector('iframe[id="launcher"]');

  // Check if the launcher iframe exists and if the next sibling is an iframe
  const nextIframe = launcherIframe?.nextElementSibling as HTMLIFrameElement;

  if (launcherIframe && nextIframe?.tagName === "IFRAME") {
    const parentDiv = launcherIframe.parentElement;

    // Check if they are both inside the same parent div and not the top-level body
    if (parentDiv?.tagName === "DIV" && parentDiv !== document.body) {
      nextIframe.remove();
    }
  }
  // Remove any iframes associated with Zendesk
  const zendeskIframes = document.querySelectorAll(
    'iframe[data-product="web_widget"], iframe#launcher',
  );

  zendeskIframes.forEach((iframe) => {
    iframe.remove();
  });

  // Remove global references to Zendesk
  const zendeskGlobals = ["zE", "zEmbed", "zEACLoaded", "zESettings"];

  zendeskGlobals.forEach((global) => {
    if (window[global]) {
      delete window[global];
    }
  });
};

export const closeAndHideZendeskWidget = (): void => {
  ZendeskAPI("webWidget", "close");
  ZendeskAPI("webWidget", "hide");
};

export const closeAndHideZendeskMessenger = (): void => {
  ZendeskAPI("messenger", "close");
  ZendeskAPI("messenger", "hide");
};

export const openAndShowZendeskWidget = (): void => {
  ZendeskAPI("webWidget", "open");
  ZendeskAPI("webWidget", "show");
};

type ZendeskServiceProps = {
  zendeskKey: string;
  shouldInitializeZendesk?: boolean;
  shouldInitializeMessengerZendesk?: boolean;
  defer?: boolean;
  onLoaded?: () => void;
  showLiveChat?: boolean;
  showPhoneSupport?: boolean;
  [key: string]: any; // for other possible settings
};

const useZendeskService = ({
  zendeskKey,
  shouldInitializeZendesk,
  shouldInitializeMessengerZendesk,
  defer,
  onLoaded,
  showLiveChat,
  showPhoneSupport,
}: ZendeskServiceProps): void => {
  const { isAuthenticated } = useAuth();
  const { domainSettings } = useConfigurationStore();
  const { chat_support: liveChatInPlan, phone_support: phoneSupportInPlan } =
    domainSettings?.features_allowed_in_plan ?? {};
  const { zendeskConfigDataMutation } = useZendeskConfigData();

  const userRole = authService.getDefaultRole();
  const isAdmin = userRole === userRoles.ADMINISTRATOR;

  const zendeskSettings = {
    webWidget: {
      authenticate: {
        chat: {
          jwtFn: async (jwtFnCallback: (jwt: string) => void): Promise<void> => {
            if (!isAuthenticated && (!liveChatInPlan || !phoneSupportInPlan)) return;
            zendeskConfigDataMutation("chat", {
              onSuccess: (data: ZendeskTokenRes) => {
                jwtFnCallback(data._data.jwt);
              },
            });
          },
        },
      },
      chat: {
        suppress: false,
      },
      talk: {
        nickname: "TalkWidget",
        suppress: false,
        title: {
          "*": "Talk with us!",
        },
      },
    },
  };

  if (showLiveChat && liveChatInPlan) {
    ZendeskAPI("webWidget", "updateSettings", {
      chat: {
        suppress: !showLiveChat,
      },
      talk: {
        suppress: true,
      },
    });
  } else if (showPhoneSupport && phoneSupportInPlan) {
    ZendeskAPI("webWidget", "updateSettings", {
      chat: {
        suppress: true,
      },
      talk: {
        suppress: !showPhoneSupport,
      },
    });
  }

  useEffect(() => {
    const script = document.getElementById(ZENDESK_SCRIPT_ID);
    const uniqueId = script?.dataset.uniqueId;
    if (isAuthenticated && liveChatInPlan && script && uniqueId === "help-script") {
      ZendeskAPI("webWidget", "chat:reauthenticate");
    } else if (script && uniqueId === "help-script") {
      ZendeskAPI("webWidget", "logout");
    }
  }, [isAuthenticated, liveChatInPlan]);

  useEffect(() => {
    const onScriptLoaded = (): void => {
      if (typeof onLoaded === "function") {
        onLoaded();
      }
    };

    const insertHelpScript = (zendeskKey: string, defer?: boolean): void => {
      const scriptElement = document.getElementById(ZENDESK_SCRIPT_ID);
      if (!scriptElement && isAdmin) {
        const script = document.createElement("script");

        script.id = ZENDESK_SCRIPT_ID;
        script.src = `${ZENDESK_SCRIPT_URL}${zendeskKey}`;
        script.defer = !!defer;
        script.async = !defer;

        script.dataset.uniqueId = "help-script";

        script.addEventListener("load", () => {
          closeAndHideZendeskWidget();
          window.zESettings = zendeskSettings;
          onScriptLoaded();
        });
        document.body.appendChild(script);
      } else {
        if (window.zE) {
          window.zESettings = { ...zendeskSettings };
        } else {
          // console.warn("Zendesk script already loaded but zE is not available.");
        }
      }
    };

    const insertMessengerScript = (zendeskKey: string): void => {
      const scriptElement = document.getElementById(ZENDESK_SCRIPT_ID);
      if (!scriptElement && !isAdmin) {
        const script = document.createElement("script");

        script.id = ZENDESK_SCRIPT_ID;
        script.src = `${ZENDESK_SCRIPT_URL}${zendeskKey}`;
        script.async = true;

        script.dataset.uniqueId = "messenger-script";

        script.addEventListener("load", () => {
          onScriptLoaded();
        });
        document.body.appendChild(script);
      }
    };

    if (canUseDOM() && shouldInitializeZendesk) {
      insertHelpScript(zendeskKey, defer);
    } else if (canUseDOM() && shouldInitializeMessengerZendesk) {
      insertMessengerScript(zendeskKey);
    }

    return () => {
      const script = document.getElementById(ZENDESK_SCRIPT_ID);
      const uniqueId = script?.dataset.uniqueId;
      if (script && uniqueId === "help-script") {
        script.removeEventListener("load", onScriptLoaded);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [zendeskKey, defer, onLoaded, showLiveChat, showPhoneSupport]);
};

export default useZendeskService;
