import React, { FC, useState } from "react";
import { SerializedStyles } from "@emotion/react";
import { Button, DropdownItem, Dropdown, TableProps, Text } from "@epignosis_llc/gnosis";
import { ArrowDownLargeSVG } from "@epignosis_llc/gnosis/icons";
import { tableActionsStyles } from "./styles";
import classNames from "classnames";
import { useApplyTranslations } from "@hooks";

type PlacementOptions = "bottom-start" | "bottom-end" | "top-start" | "top-end" | "end-top";

type SortingDropdownProps = {
  sortingDropdownOptions: DropdownItem[];
  selectedSorting: TableProps["sorting"];
  filters?: string[];
  onSortingSelect: (sorting: TableProps["sorting"]) => void;
  disabled?: boolean;
  placement?: PlacementOptions;
};

const SortingButtonClassnames = (isListOpen: boolean): string =>
  classNames({
    "sorting-button": true,
    "sorting-name": true,
    "is-list-open": isListOpen,
  });

const MIN_SORTING_NUMBER = 10;

const SortingDropdown: FC<SortingDropdownProps> = ({
  sortingDropdownOptions = [],
  selectedSorting,
  filters,
  onSortingSelect,
  disabled = false,
  placement = "bottom-start",
}) => {
  const { t, i18n } = useApplyTranslations();
  const isRtl = i18n.dir() === "rtl";
  const [listStatus, setListStatus] = useState(false);
  // make a flat array without all available filters
  const allSortingItems = sortingDropdownOptions.map((sorting) => sorting.items ?? []).flat();

  // Only show search if there are more than 10 filter options
  const showSearch = allSortingItems.length > MIN_SORTING_NUMBER;

  //filtered dropdown options
  const filteredOptions = filters
    ? sortingDropdownOptions.filter((option) => filters?.includes(option?.value ?? ""))
    : sortingDropdownOptions;

  const selectedSortingLabel = sortingDropdownOptions.find(
    (column) => column.value === selectedSorting?.column,
  )?.label;

  const selectedSortingId = sortingDropdownOptions.find(
    (column) => column.value === selectedSorting?.column,
  )?.value;

  const handleOnClick = (): void => {
    if (!disabled) {
      onSortingSelect({
        ...selectedSorting,
        isDescending: !selectedSorting?.isDescending,
      });
    }
  };

  return (
    <div
      css={(theme): SerializedStyles => tableActionsStyles(theme, { selectedSortingId, isRtl })}
      className="actions-container"
    >
      <Text fontSize="sm" className="sortby-text">
        {t("sorting.sortBy")}
      </Text>
      <div className="sorting-container">
        {filteredOptions.length > 1 ? (
          <Dropdown
            list={filteredOptions}
            onListItemSelect={(item: DropdownItem): void => {
              onSortingSelect({ column: item.value, isDescending: item.id === "desc" });
            }}
            isSearchable={showSearch}
            onToggleList={(value): void => setListStatus(value)}
            disabled={disabled}
            placement={placement}
          >
            <Button
              color="secondary"
              as="div"
              aria-label="sorting-dropdown"
              className={SortingButtonClassnames(listStatus)}
              disabled={disabled}
            >
              {selectedSortingLabel}
            </Button>
          </Dropdown>
        ) : (
          <Button
            color="secondary"
            as="div"
            aria-label="sorting-dropdown"
            className="sorting-button-nonclickable"
            disabled={disabled}
          >
            {selectedSortingLabel}
          </Button>
        )}

        <Button
          color="secondary"
          as="div"
          aria-label="sorting-dropdown"
          className="sorting-button"
          onClick={handleOnClick}
          disabled={disabled}
          noGutters
          role="button"
        >
          {selectedSorting?.isDescending ? (
            <ArrowDownLargeSVG height={20} />
          ) : (
            <ArrowDownLargeSVG height={20} style={{ transform: "rotate(180deg)" }} />
          )}
        </Button>
      </div>
    </div>
  );
};

export default SortingDropdown;
