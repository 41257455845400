// Packages or third-party libraries
import React, { FC, useState } from "react";
import { Controller, UseFormReturn } from "react-hook-form";
import ReactPlayer from "react-player";
import { Button, Grid, Input, InputError } from "@epignosis_llc/gnosis";
import { UnitVideoSVG, TrashSVG } from "@epignosis_llc/gnosis/icons";

// Components
import { DragAndDropArea, FileFailed, FileInput, FileProcessing, TextWithIcon } from "@components";
import { VideoType } from "../../../CourseOptionsDrawer";
import { PressedRectangle } from "@components/ReusableComponents";

// Hooks, utils etc...
import {
  getFileValidations,
  buildFileValidations,
  errorNotification,
  isValidYoutubeOrVimeoUrl,
} from "@utils/helpers";
import { useApplyTranslations } from "@hooks";

// Constants
import { courseOptionsIds } from "../../constants";

// Other imports
import { EditCourseData } from "@views/CourseEdit/api";
import { Course } from "types/entities";
import { CourseIntroVideoStatus } from "types/entities/Courses";

const MAX_ALLOWED_FILES = 1;
const REACT_PLAYER_CONFIG = {
  file: {
    attributes: {
      controlsList: "nodownload noplaybackrate",
      disablePictureInPicture: true,
      crossOrigin: "true",
    },
  },
};

type IntroVideoOptionsProps = {
  videoPreviewFile: File | null;
  course: Course;
  form: UseFormReturn<EditCourseData>;
  handleFileChanged: (file: File | null) => void;
  setShouldDeleteVideo: (shouldDelete: boolean) => void;
};

const IntroVideoOptions: FC<IntroVideoOptionsProps> = ({
  course,
  form,
  handleFileChanged,
  videoPreviewFile,
  setShouldDeleteVideo,
}) => {
  const { t } = useApplyTranslations();
  const { intro_video } = course ?? {};
  const { url, type, status } = intro_video ?? {};
  const initialCustomUrl = type == "file" ? url : undefined;
  const initialYoutubeUrl = type == "youtube" ? url : undefined;

  const [videoType, setVideoType] = useState<VideoType | undefined>(type);
  const [youtubeUrl, setYoutubeUrl] = useState<string | undefined>(initialYoutubeUrl);
  const [customUrl, setCustomUrl] = useState<string | undefined>(initialCustomUrl);
  const [droppedAttachments, setDroppedAttachments] = useState<FileList | null>(null);
  const selectedFiles = videoPreviewFile ? [videoPreviewFile] : [];

  const {
    setValue,
    setError,
    control,
    formState: { errors },
  } = form;
  const isCustomFileVideo = videoType == "file";
  const isYoutubeVideo = videoType == "youtube";

  const validations = getFileValidations(["course.intro_video"]);
  const mimeTypeAndFilesizeValidations = {
    ...buildFileValidations(validations),
  };

  const handleClick = (type: VideoType): void => {
    setVideoType(type);
  };

  const handleFilesChanged = (files: File[]): void => {
    setCustomUrl(files.length ? undefined : initialCustomUrl);
    handleFileChanged(files[0]);
    setShouldDeleteVideo(false);
  };

  const handleFileError = (error: string): void => {
    errorNotification(`${error}`);
  };

  const shouldShowPreview = (): boolean => {
    if (isYoutubeVideo) {
      return Boolean(isValidYoutubeOrVimeoUrl(youtubeUrl ?? ""));
    }

    if (isCustomFileVideo) {
      return type === "file" && Boolean(customUrl) && status === CourseIntroVideoStatus.Ready;
    }

    return false;
  };

  const clearAll = (): void => {
    setVideoType(undefined);
    handleFileChanged(null);
    setYoutubeUrl(undefined);
    setCustomUrl(undefined);
    setValue("intro_video_url", null);
    setShouldDeleteVideo(true);
  };

  const shouldShowClearButton = (): boolean => {
    return Boolean(
      (youtubeUrl || customUrl || videoPreviewFile) && status !== CourseIntroVideoStatus.Failed,
    );
  };

  const handleFilesDrop = (files: FileList): void => {
    setDroppedAttachments(files);
  };

  return (
    <div className="intro-video-options-container" id={courseOptionsIds.introVideoOptions}>
      <div className="intro-video-header">
        <TextWithIcon icon={<UnitVideoSVG height={32} />} label={t("general.introVideo")} />
        {shouldShowClearButton() && (
          <Button
            variant="ghost"
            aria-label="clear-intro-video"
            onClick={clearAll}
            iconBefore={TrashSVG}
          >
            {t("general.clear")}
          </Button>
        )}
      </div>
      <Grid templateColumns={[1, 1, 1, 2]} rowGap={1} columnGap={1} className="grid-container">
        <Grid.Item colSpan={1}>
          <PressedRectangle
            data-testid="intro-youtube-video"
            content={t("courseEdit.youtubeVideo")}
            isPressed={isYoutubeVideo}
            type="youtube"
            onClick={handleClick}
          />
        </Grid.Item>
        <Grid.Item colSpan={1}>
          <PressedRectangle
            data-testid="intro-custom-video"
            content={t("courseEdit.customVideo")}
            isPressed={isCustomFileVideo}
            type="file"
            onClick={handleClick}
          />
        </Grid.Item>
        <Grid.Item colSpan={[1, 1, 1, 2]}>
          {isYoutubeVideo && (
            <>
              <Controller
                name="intro_video_url"
                control={control}
                render={({ field: { onChange, value } }): JSX.Element => (
                  <Input
                    id="intro-video-url"
                    type="text"
                    label="URL"
                    size="md"
                    value={value ?? ""}
                    tooltipContent={t("courseEdit.videoLinkInfo")}
                    onChange={(e): void => {
                      const newValue = e.target.value;
                      onChange(newValue);
                      setYoutubeUrl(newValue);
                      setShouldDeleteVideo(false);
                    }}
                  />
                )}
              />
              {errors.intro_video_url && <InputError>{errors.intro_video_url.message}</InputError>}
            </>
          )}

          {isCustomFileVideo && status !== CourseIntroVideoStatus.Processing && (
            <>
              <DragAndDropArea
                className="upload-prompt-area"
                maxFiles={MAX_ALLOWED_FILES}
                mimeTypeAndFilesizeValidations={mimeTypeAndFilesizeValidations}
                preventDrop={selectedFiles.length >= MAX_ALLOWED_FILES}
                onFilesDrop={handleFilesDrop}
              >
                <FileInput
                  id="upload-course-file"
                  name="files"
                  accept="video/*"
                  maxFiles={MAX_ALLOWED_FILES}
                  mimeTypeAndFilesizeValidations={mimeTypeAndFilesizeValidations}
                  selectedFiles={selectedFiles}
                  addedFiles={droppedAttachments}
                  onFileError={handleFileError}
                  onFilesChange={handleFilesChanged}
                />
              </DragAndDropArea>
            </>
          )}
        </Grid.Item>
        <Grid.Item colSpan={[1, 1, 1, 2]}>
          <div className="intro-video-wrapper">
            {shouldShowPreview() && (
              <ReactPlayer
                id="intro-video"
                className="intro-video"
                url={isYoutubeVideo ? youtubeUrl : customUrl}
                config={REACT_PLAYER_CONFIG}
                width="100%"
                height="100%"
                onError={(): void => {
                  setError("intro_video_url", {
                    type: "manual",
                    message: t("courseEdit.validationMessages.invalidIntroVideoLink"),
                  });
                }}
                controls
              />
            )}
            {!isYoutubeVideo && status !== CourseIntroVideoStatus.Ready && (
              <div className="intro-video-status">
                {status === CourseIntroVideoStatus.Processing && (
                  <FileProcessing info={t("errors.units.fileIsProcessingSubtitle")} />
                )}
                {status === CourseIntroVideoStatus.Failed && (
                  <FileFailed info={t("errors.units.fileProcessingSubtitle")} />
                )}
              </div>
            )}
          </div>
        </Grid.Item>
      </Grid>
    </div>
  );
};

export default IntroVideoOptions;
