// Packages or third-party libraries
import React, { FC } from "react";
import { useResponsive } from "ahooks";
import { DropdownItem } from "@epignosis_llc/gnosis";

// Styles
import { iltSessionStyles } from "./styles";

// Components
import { VerticalDivider } from "@components/ReusableComponents";
import { IltTitle } from "./IltTitle";
import IltSessionDate from "./IltSessionDate";
import IltSessionDescription from "./IltSessionDescription";
import IltSessionDetails from "./IltSessionDetails";
import IltSessionActions from "./IltSessionActions";

// Utils
import { isSessionLive } from "@utils/helpers";

// Other imports
import { Session, SessionItem } from "types/entities/Unit";
import { useConfigurationStore } from "@stores";

type IltItemProps = {
  sessionItem: SessionItem;
  session: Session;
  showTitle: boolean;
  maxCols?: number;
  shouldDisableGrayOutDate?: boolean;
  isAdmin?: boolean;
  hideActions?: boolean;
  dropdownOptions?: DropdownItem[];
  isPreview?: boolean;
  onListItemSelect?: (item: DropdownItem) => void;
};

const IltSession: FC<IltItemProps> = ({
  sessionItem,
  session,
  showTitle,
  maxCols = 5,
  shouldDisableGrayOutDate = false,
  isAdmin = false,
  hideActions = false,
  dropdownOptions,
  isPreview = false,
  onListItemSelect,
}) => {
  const { lg } = useResponsive();
  const userProfileData = useConfigurationStore((state) => state.userProfileData);
  const { id: userId } = userProfileData ?? {};

  const {
    name,
    start_datetime,
    end_datetime,
    type,
    description,
    webinar_id,
    join_url,
    instructor,
  } = sessionItem;
  const { is_registered } = session;

  const isLive = isSessionLive(start_datetime, end_datetime) && (isAdmin || is_registered);
  const isWebinar = Boolean(type === "webinar" && webinar_id);
  // in admin show webinar join button when the webinar instructor is the authenticated user
  const showJoinBtnInAdmin = isAdmin && userId === instructor?.id;
  const showJoinBtn = isWebinar && (showJoinBtnInAdmin || is_registered);
  const isMobile = !lg;

  return (
    <div css={iltSessionStyles}>
      <div className="session-wrapper">
        <div className="session-container">
          <IltSessionDate
            startDatetime={start_datetime}
            endDatetime={end_datetime}
            shouldDisableGrayOutDate={shouldDisableGrayOutDate}
          >
            {isMobile && !hideActions && (
              <IltSessionActions
                showJoinBtn={showJoinBtn}
                joinUlr={join_url}
                webinarId={webinar_id}
                dropdownOptions={dropdownOptions}
                onListItemSelect={onListItemSelect}
              />
            )}
          </IltSessionDate>

          {!isMobile && <VerticalDivider />}

          {/* not show multiple session icon */}
          {isMobile && showTitle && (
            <IltTitle
              name={name}
              showMultiSession={false}
              isLive={isLive}
              endDatetime={end_datetime}
            />
          )}

          <IltSessionDetails
            sessionItem={sessionItem}
            session={session}
            maxCols={maxCols}
            showTitle={showTitle}
            isPreview={isPreview}
            isLive={isLive}
          >
            {!isMobile && !hideActions && (
              <IltSessionActions
                showJoinBtn={showJoinBtn}
                joinUlr={join_url}
                webinarId={webinar_id}
                dropdownOptions={dropdownOptions}
                onListItemSelect={onListItemSelect}
              />
            )}
          </IltSessionDetails>
        </div>
      </div>

      <IltSessionDescription description={description} />
    </div>
  );
};

export default IltSession;
