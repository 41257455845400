import React, { FC, MouseEvent, useEffect, useState } from "react";
import { Button, Modal, Text, Checkbox } from "@epignosis_llc/gnosis";
import { modalFooter, modalBody } from "./styles";
import useUIStore from "@stores/ui";
import { sanitizeData } from "./utils";
import { useApplyTranslations } from "@hooks";

export type ConfirmationModalProps = {
  id: number | string;
  header: string;
  bodyTitle: JSX.Element | string;
  bodyText?: JSX.Element | string;
  footerButton?: JSX.Element | string;
  isOpen: boolean;
  hasDontAskAgainCheckbox?: boolean;
  closeOnOutsideClick?: boolean;
  isLoading?: boolean;
  restrictedBody?: boolean;
  confirmationButtonColor?: "primary" | "secondary" | "success" | "danger";
  disableConfirmButton?: boolean;
  shouldCallOnClose?: boolean;
  onClose: () => void | Promise<void>;
  onConfirm?: (id: number | string) => void | Promise<void>;
};

const ConfirmationModal: FC<ConfirmationModalProps> = ({
  id,
  header,
  bodyTitle,
  bodyText,
  footerButton,
  isOpen,
  isLoading = false,
  hasDontAskAgainCheckbox = false,
  closeOnOutsideClick = false,
  disableConfirmButton = false,
  restrictedBody = false,
  confirmationButtonColor = "danger",
  shouldCallOnClose = true,
  onClose,
  onConfirm,
}) => {
  const { t } = useApplyTranslations();
  const [shouldAskAgain, setShouldAskAgain] = useState(false);
  const { shouldShowConfirmationModal, setShouldShowConfirmationModal } = useUIStore(
    (state) => state,
  );

  useEffect(() => {
    if (!shouldShowConfirmationModal && hasDontAskAgainCheckbox) {
      id && hasDontAskAgainCheckbox && onConfirm && onConfirm(id);
      onClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleConfirm = (e: MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    id && onConfirm && onConfirm(id);

    hasDontAskAgainCheckbox && setShouldShowConfirmationModal(shouldAskAgain);
    shouldCallOnClose && onClose();
  };

  const handleDontAsk = (): void => {
    setShouldAskAgain(!shouldAskAgain);
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} closeOnOutsideClick={closeOnOutsideClick}>
      <Modal.Header>{header}</Modal.Header>
      <Modal.Body style={{ maxHeight: 600, overflow: "auto" }}>
        <div css={modalBody(restrictedBody)}>
          <Text fontSize="sm" as="div" className="subject-text">
            {sanitizeData(bodyTitle)}
          </Text>
          <Text fontSize="sm" as="div" className="body-text">
            {sanitizeData(bodyText)}
          </Text>

          {hasDontAskAgainCheckbox && (
            <div className="dontaskagain-checkbox">
              <Checkbox
                id="dont-ask-again"
                name={t("general.dontAskAgain")}
                label={t("general.dontAskAgain")}
                value={shouldAskAgain.toString()}
                onChange={handleDontAsk}
                checked={shouldAskAgain}
              />
            </div>
          )}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div css={modalFooter}>
          {footerButton && typeof footerButton === "string" ? (
            <Button
              isLoading={isLoading}
              onClick={handleConfirm}
              color={confirmationButtonColor}
              disabled={disableConfirmButton}
            >
              {footerButton}
            </Button>
          ) : (
            footerButton
          )}
          <Button color="secondary" onClick={onClose} className="cancel-btn">
            {t("general.cancel")}
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmationModal;
