import axios from "axios";
import { logAxiosError } from "./helpers";
import { refreshToken } from "@api/app";
import authService from "@utils/services/AuthService";
import { useConfigurationStore, useUIStore, useUserPreferencesStore } from "@stores";
import { config, getBaseUrl } from "@config";
import { URLS } from "@constants/urls";
import queryParameters from "@constants/queryParameters";
import { parseAccessToken } from "@utils/helpers";
import localStorageKeys from "@constants/localStorageKeys";
import LogTraceIdService from "@utils/services/LogTraceIdService";

const axiosApiInstance = axios.create();
let isRefreshingToken = false;
const { getState: getUIStoreState } = useUIStore;
const userStore = useUserPreferencesStore;
const { getState: getUserPreferencesState } = userStore();
const { resetSettings } = getUserPreferencesState() ?? {};
const { getState: getConfigurationState } = useConfigurationStore;
const { setUserProfile } = getConfigurationState();
const { showSessionExpirationModal, setShowSessionExpirationModal, setShowTermsModal } =
  getUIStoreState();
const logoutErrorsStatuses = [403, 404, 409];

// error that required user logout
const logoutErrors = [
  "forbidden.locked_portal",
  "forbidden.user_not_allowed_to_access_plus",
  "forbidden.user_not_member_in_branch",
  "forbidden.branch_inactive",
  // deleted user errors
  "not_found.user_not_found",
  "conflict.user_not_active",
];

const handleSSOLogout = (): void => {
  const ssoLogoutUrl = localStorage.getItem(localStorageKeys.SSO_LOGOUT_URL);

  authService.removeRole();
  resetSettings();
  setUserProfile(null);

  ssoLogoutUrl
    ? // redirect to sso logout url
      window.location.replace(ssoLogoutUrl)
    : // logout
      window.location.replace(URLS.login);
};

axiosApiInstance.interceptors.request.use(
  async (config) => {
    const token = authService.getAccessToken();
    const userRole = authService.getDefaultRole();
    const xRequestId = LogTraceIdService.generateXRequestId();

    // Config Headers
    if (token) {
      config.headers = {
        Authorization: `Bearer ${token}`,
        "Current-Role": userRole,
      };
    }

    // Attach X-Request-Id to the request headers no matter if authorized or not.
    config.headers = {
      ...config.headers,
      "X-Request-Id": xRequestId,
    };

    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

axiosApiInstance.interceptors.response.use(
  (res) => {
    return res;
  },
  async (error) => {
    const originalRequest = error?.config;
    const errorResponse = error?.response;
    const responseStatus = errorResponse?.status;
    const token = authService.getAccessToken();
    const decodeToken = token ? parseAccessToken(token) : null;
    const isSSOLogin = decodeToken?.sub.is_sso_login;
    const tokenExpired = responseStatus === 401 && !originalRequest._retry;
    const errorId = errorResponse?.data?._errors?.[0]?.id;

    logAxiosError(error);

    if (token) {
      if (logoutErrorsStatuses.includes(responseStatus) && logoutErrors.includes(errorId)) {
        authService.removeTokens();
        authService.removeRole();
        window.location.href = getBaseUrl();
        return Promise.reject(error);
      }

      // there are empty mandatory custom fields in user's profile
      if (responseStatus === 403 && errorId === "forbidden.empty_custom_fields") {
        if (!window.location.pathname.includes(URLS.user.profile)) {
          const pathname = window.location.pathname.startsWith("/plus")
            ? window.location.pathname.replace("/plus", "")
            : window.location.pathname;

          const redirectParam = pathname + window.location.search;

          const encodedRedirectParam = encodeURIComponent(redirectParam);

          const queryParam = `?${queryParameters.redirect}=${encodedRedirectParam}`;

          window.location.href = config.isDev()
            ? `${URLS.user.profile}${queryParam}`
            : `${getBaseUrl()}${URLS.user.profile}${queryParam}`;
        }
        return Promise.reject(error);
      }

      // Redirect to onboarding welcome page if the user hasn't answered welcome questions
      if (responseStatus === 403 && errorId === "forbidden.welcome_questions_not_answered") {
        if (!window.location.pathname.includes(URLS.onboardingWelcome)) {
          window.location.href = config.isDev()
            ? URLS.onboardingWelcome
            : `${getBaseUrl()}${URLS.onboardingWelcome}`;
        }
        return Promise.reject(error);
      }

      // Redirect to onboarding not available page if the user is sanctioned
      if (responseStatus === 403 && errorId === "forbidden.user_is_sanctioned") {
        if (!window.location.pathname.includes(URLS.onboardingNotAvailable)) {
          window.location.href = config.isDev()
            ? URLS.onboardingNotAvailable
            : `${getBaseUrl()}${URLS.onboardingNotAvailable}`;
        }
        return Promise.reject(error);
      }
    }

    if (!tokenExpired || isRefreshingToken || !token || showSessionExpirationModal) {
      return Promise.reject(error);
    }

    // set refresh token flag to true in order to not have multiple refresh token requests run the same time
    isRefreshingToken = true;

    originalRequest._retry = true;
    const oldRefreshToken = authService.getRefreshToken() as string;
    const response = await refreshToken(oldRefreshToken);

    // reset refresh token flag
    isRefreshingToken = false;

    // success token refresh
    if (response && response.status === 200) {
      authService.setTokens(response.data);
      axios.defaults.headers.common["Authorization"] = `Bearer ${response.data.access_token}`;
      return axiosApiInstance(originalRequest);

      // error on token refresh
    } else {
      authService.removeTokens();

      if (!showSessionExpirationModal) {
        // if user used sso to login

        !isSSOLogin ? setShowSessionExpirationModal(true) : handleSSOLogout();

        setShowTermsModal(false);
      }

      return Promise.reject(response);
    }
  },
);

export default axiosApiInstance;
